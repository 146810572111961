<template>
  <div class="mt-2">
    <v-row no-gutters v-if="fieldsLoading">
      <v-col v-for="n in 9" :key="n" cols="12" sm="6" lg="3">
       <v-sheet class="mt-2">
        <v-skeleton-loader class="mx-auto" type="list-item"></v-skeleton-loader>
      </v-sheet>
      </v-col>
    </v-row>
    <form-data v-else :references.sync="formReferences" :model="deviationObj" ref="deviationReference">
      <template slot="deviation_dateTime">
        <datetime-picker :label="$t('message.common.date')" :dateMax="new Date().toISOString().slice(0,10)" :timeMax="`${new Date().getHours()}:${new Date().getMinutes()}`" v-model="deviationObj.deviation_date" clearable prepend-inner-icon="mdi-calendar"></datetime-picker>
      </template>
      <template v-if="deviationObj.id && listOfDocuments.length > 0" slot="deviation_documents">
        <documents :documents="listOfDocuments" :deleteIds="deleteDocumentIds"></documents>
      </template>
    </form-data>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { hostAppApi } from '../../plugins/axios_settings'
import PermissionsMixin from '@/mixins/permissions'
import moment from 'moment'
import projectMixin from '@/mixins/projectMixin'
export default {
  mixins: [PermissionsMixin, projectMixin],
  data () {
    return {
      listOfProjects: [],
      listOfLocations: [],
      listOfConsequences: [],
      listOfResponsibleUsers: [],
      listOfCopyToUsers: [],
      listCategories: [],
      deviationObj: {
        id: parseInt(this.$route.params.id),
        project_id: this.$route.query.project_id ? parseInt(this.$route.query.project_id) : 0,
        severity: 1,
        status: 2,
        deadline: moment().format('DD.MM.YYYY'),
        deviation_date: moment().format('DD.MM.YYYY HH:mm'),
        maxEndDate: null
      },
      listOfSeverities: [
        { text: this.$t('message.deviation.low'), value: 1 },
        { text: this.$t('message.deviation.medium'), value: 2 },
        { text: this.$t('message.deviation.high'), value: 3 }
      ],
      listOfStatus: [
        { text: this.$t('message.deviation.registered'), value: 1 },
        { text: this.$t('message.deviation.process'), value: 2 },
        { text: this.$t('message.deviation.closed'), value: 3 }
      ],
      loading: false,
      hostRef: this.$cookie.get('WelditPwa_host-ref'),
      projectId: this.$route.query.project_id || 0,
      activeDeviationId: 0,
      fieldsLoading: false,
      isReadonly: false,
      hostUserId: this.$cookie.get(process.env.VUE_APP_HOST_USER) ? this.$cookie.get(process.env.VUE_APP_HOST_USER) : 0,
      backUrl: '',
      listOfDocuments: [],
      deleteDocumentIds: [],
      hideDetails: true,
      /* #11888-OP */
      deviationTypes: [
        { text: this.$t('message.deviation.deviations'), value: 1 },
        { text: this.$t('message.deviation.changeRequest'), value: 2 },
        { text: this.$t('message.deviation.hse'), value: 3 }
      ]
    }
  },
  computed: {
    ...mapGetters(['formType', 'generalSettings', 'userDetails']),
    formReferences () {
      /* Form reference reason field before the upload field - can be removed
      {
        model: 'reason',
        type: this.formType.TEXTAREA,
        rules: [],
        label: this.$t('message.deviation.reason'),
        class: 'xs12 sm4 md3 lg3',
        rows: 3
      },  */
      return {
        cardFlat: true,
        cardTitleClass: 'pa-1',
        layoutClass: 'pa-1',
        title: this.isReadonly ? this.$t('message.deviation.view_deviation') : this.$t('message.deviation.register_deviation'),
        properties: [{
          model: 'deviation_type',
          type: this.formType.SELECT,
          rules: this.$_requiredValidation,
          items: this.deviationTypes,
          select_text: 'text',
          select_value: 'value',
          disabled: this.isReadonly,
          label: this.$t('message.common.type'),
          class: 'xs12 sm4 md3 lg3'
        }, {
          model: 'project_id',
          type: this.formType.AUTO_COMPLETE,
          rules: [],
          items: this.listOfProjects,
          select_text: (item) => this.setProjectname(item),
          select_value: 'id',
          disabled: Boolean(this.projectId) || this.isReadonly,
          label: this.$t('message.projects.project'),
          class: 'xs12 sm4 md3 lg3',
          is_show: !this.$store.state.common.isProjectExcluded
        }, {
          model: 'title',
          type: this.formType.TEXT,
          rules: [...this.$_requiredValidation, ...this.$_sharepointCharacters],
          label: this.$t('message.deviation.title'),
          disabled: this.isReadonly,
          class: 'xs12 sm4 md3 lg3'
        }, {
          model: 'deviation_number',
          type: this.formType.TEXT,
          rules: this.$_requiredValidation,
          label: this.$t('message.deviation.deviation_number'),
          disabled: true,
          class: 'xs12 sm4 md3 lg3'
        }, {
          model: 'location_id',
          type: this.formType.SELECT,
          rules: this.$_requiredValidation,
          items: this.listOfLocations,
          select_text: 'name',
          select_value: 'id',
          disabled: this.isReadonly,
          label: this.$t('message.deviation.location'),
          class: 'xs12 sm4 md3 lg3'
        }, {
          model: 'category_id',
          type: this.formType.SELECT,
          rules: this.$_requiredValidation,
          items: this.listCategories,
          select_text: 'name',
          select_value: 'id',
          disabled: this.isReadonly,
          label: this.$t('message.deviation.category'),
          class: 'xs12 sm4 md3 lg3'
        }, {
          model: 'estimate_cost',
          type: this.formType.TEXT,
          rules: [],
          label: this.$t('message.deviation.estimate_cost'),
          disabled: this.isReadonly,
          class: 'xs6 sm4 md3 lg3'
        }, {
          model: 'deviation_date',
          type: this.formType.DATETIMEPICKER,
          slot: 'deviation_dateTime',
          class: 'xs12 sm4 md3 lg3'
        }, {
          model: 'deadline',
          type: this.formType.DATEPICKER,
          rules: [],
          picker: 'deadlineDatePicker',
          menu: 'deadlineDateMenu',
          color: 'primary lighten-1',
          min: 'maxEndDate',
          disabled: this.isReadonly,
          label: this.$t('message.deviation.deadline'),
          class: 'xs6 sm4 md3 lg3'
        }, {
          model: 'consequence_id',
          type: this.formType.SELECT,
          rules: [],
          items: this.listOfConsequences,
          select_text: (item) => item.name,
          select_value: 'id',
          disabled: this.isReadonly,
          label: this.$t('message.deviation.consequences'),
          class: 'xs12 sm4 md3 lg3'
        }, {
          model: 'severity',
          type: this.formType.SELECT,
          rules: this.$_requiredValidation,
          items: this.listOfSeverities,
          select_text: 'text',
          select_value: 'value',
          disabled: this.isReadonly,
          label: this.$t('message.deviation.severity'),
          class: 'xs6 sm4 md3 lg3'
        }, {
          model: 'status',
          type: this.formType.SELECT,
          rules: this.$_requiredValidation,
          items: this.listOfStatus,
          select_text: 'text',
          select_value: 'value',
          disabled: this.isReadonly,
          label: this.$t('message.deviation.deviation_status'),
          class: 'xs6 sm4 md3 lg3'
        }, {
          model: 'reported_by',
          type: this.formType.TEXT,
          rules: this.$_requiredValidation,
          label: this.$t('message.deviation.reported_by'),
          disabled: this.isReadonly,
          class: 'xs12 sm4 md3 lg3'
        }, {
          model: 'responsible_id',
          type: this.formType.SELECT,
          rules: this.$_requiredValidation,
          items: this.listOfResponsibleUsers,
          select_text: 'name',
          select_value: 'id',
          disabled: this.isReadonly,
          label: this.$t('message.deviation.responsible'),
          class: 'xs12 sm4 md3 lg3'
        }, {
          model: 'copyto',
          type: this.formType.SELECT,
          rules: [],
          items: this.listOfCopyToUsers,
          select_text: 'name',
          select_value: 'id',
          disabled: this.isReadonly,
          label: this.$t('message.deviation.copyto'),
          class: 'xs12 sm4 md3 lg3',
          multiple: true,
          select_all: true
        }, {
          model: 'reason',
          type: this.formType.TEXTAREA,
          rules: [],
          label: this.$t('message.deviation.reason'),
          class: 'xs12 sm4 md3 lg3',
          disabled: this.isReadonly,
          rows: 3
        }, {
          model: 'underlying_reason',
          type: this.formType.TEXTAREA,
          rules: [],
          label: this.$t('message.deviation.underlying_reason'),
          class: 'xs12 sm4 md3 lg3',
          disabled: this.isReadonly,
          rows: 3
        }, {
          model: 'corrective_actions',
          type: this.formType.TEXTAREA,
          rules: [],
          label: this.$t('message.deviation.corrective_actions'),
          class: 'xs12 sm4 md3 lg3',
          disabled: this.isReadonly,
          rows: 3
        }, {
          model: 'effect_of_measures',
          type: this.formType.TEXTAREA,
          rules: [],
          label: this.$t('message.deviation.effect_of_measures'),
          class: 'xs12 sm4 md3 lg3',
          disabled: this.isReadonly,
          rows: 3
        }, {
          model: 'description',
          type: this.formType.TEXTAREA,
          rules: [],
          label: this.$t('message.deviation.description'),
          class: 'xs12 sm4 md3 lg3',
          disabled: this.isReadonly,
          rows: 3
        }, {
          model: 'actions',
          type: this.formType.TEXTAREA,
          rules: [],
          label: this.$t('message.deviation.actions'),
          class: 'xs12 sm4 md3 lg3',
          disabled: this.isReadonly,
          rows: 3
        }, {
          model: 'files',
          type: this.formType.FILES,
          multiple: true,
          rules: this.$_maxFileSizeValidation(100, this.deviationObj.files), // this.$_multiSelectValidation // size in MB
          accept: '.xlsx, .xls, image/*, .doc, .docx, .txt, .pdf',
          label: this.$t('message.deviation.upload_files'),
          class: 'xs12 sm4 md3',
          disabled: this.isReadonly,
          slot: 'deviation_documents',
          hideDetails: this.hideDetails
        }, {
          model: 'notify_by_mail',
          type: this.formType.CHECKBOX,
          rules: [],
          label: this.$t('message.equipments.notifyByEmail'),
          class: 'xs12 sm4 md3 lg3',
          disabled: this.isReadonly,
          rows: 3
        }],
        buttons: [{
          name: 'save_deviation',
          color: 'success',
          label: this.deviationObj.id ? this.$t('message.common.update') : this.$t('message.common.save'),
          click: this.saveUpdateHandler,
          loading: this.loading,
          disabled: this.isReadonly,
          is_show: true
        }, {
          name: 'back',
          color: 'info',
          click: () => this.$router.go(-1), // this.$router.push(this.backUrl),
          label: this.$t('message.login.back'),
          to: this.backUrl,
          is_show: true
        }]
      }
    }
  },
  mounted () {
    if (this.$store.state.common.isDeviationExcluded) this.$router.push('/')
    else {
      if (!this.hostRef.includes(this.$store.state.common.host)) {
        this.hostRef += this.$store.state.common.host
      }
      this.getAllProjects()
      this.getAllUsers()
      this.getCategories()
      this.getLocations()
      this.getConsequences()

      /* new crud codes adding */
      if (this.$route.params.id) {
        this.getSingleRecord(this.$route.params.id)
        this.activeDeviationId = parseInt(this.$route.params.id)
      } else {
        this.getDeviationNumber()
        this.getCurrentUser()
        // for create set the default values
        // if (this.userDetails) {
        //   this.deviationObj.reported_by = this.userDetails.name
        //   this.deviationObj.responsible_id = this.userDetails.id
        // }
      }
      // set back url
      this.backUrl = this.projectId ? `/projects/${this.projectId}/deviations` : '/deviations'
    }
  },
  created () {
    this.MODULE = 'deviation'
    if (this.$route.query.project_id) this.MODULE = 'projectdeviation'
    if (this.$route.name === 'deviation_edit') this.isReadonly = !this.checkActionPermission(this.MODULE, this.CONSTANTS.EDIT)
    if (this.$route.name === 'deviation_add') this.isReadonly = !this.checkActionPermission(this.MODULE, this.CONSTANTS.CREATE)
  },
  components: {
    'form-data': () => import('@/components/FormTemplate'),
    documents: () => import('@/components/Documents/Documents'),
    'datetime-picker': () => import('@/components/DateTimePicker')
  },
  methods: {
    getAllProjects () {
      this.$api.execute('get', `projects/get_as_simplified_for_pwa?filter=${this.isAdmin() ? 3 : 2}`).then((response) => {
        this.listOfProjects = response.data
      })
    },
    getAllUsers () {
      this.$api.execute('get', 'users').then(response => {
        const users = response.data
        for (let i = 0; i < users.length; i++) {
          users[i].name = `${users[i].firstname || ''} ${users[i].lastname || ''}`
        }
        const responsibleUsers = users.filter(x => x.active && x.is_responsible)
        const copyToUsers = users.filter(x => x.active && x.is_copyto)
        this.listOfResponsibleUsers = responsibleUsers
        this.listOfCopyToUsers = copyToUsers
      })
    },
    getCurrentUser () {
      this.$api.execute('get', 'users/get_current_user').then(response => {
        this.deviationObj.reported_by = response.data.name
      })
    },
    getCategories () {
      hostAppApi.get(`https://${this.hostRef}/api/get_active_categories?locale=${this.$i18n.locale}`)
        .then(response => {
          this.listCategories = response.data
        }).catch(err => {
          if (err && err.message) {
            this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: JSON.stringify(err.message), isException: true })
          }
        })
    },

    getLocations () {
      hostAppApi.get(`https://${this.hostRef}/api/get_active_locations?locale=${this.$i18n.locale}`)
        .then(response => {
          this.listOfLocations = response.data
        }).catch(err => {
          if (err && err.message) {
            this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: JSON.stringify(err.message), isException: true })
          }
        })
    },
    getDeviationNumber () {
      hostAppApi.get(`https://${this.hostRef}/api/get_deviation_number`)
        .then(response => {
          this.deviationObj.deviation_number = response.data
        }).catch(err => {
          if (err && err.message) {
            this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: JSON.stringify(err.message), isException: true })
          }
        })
    },
    getConsequences () {
      hostAppApi.get(`https://${this.hostRef}/api/get_active_consequences?locale=${this.$i18n.locale}`)
        .then(response => {
          this.listOfConsequences = response.data
        }).catch(err => {
          if (err && err.message) {
            this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: JSON.stringify(err.message), isException: true })
          }
        })
    },
    saveUpdateHandler () {
      if (this.$refs.deviationReference.$refs.validateForm.validate()) {
        this.loading = true
        const model = this.$formatter.cloneVariable(this.deviationObj)
        model.user_id = parseInt(this.hostUserId)
        model.copyto = (model.copyto && model.copyto.length > 0) ? model.copyto.join(',') : ''
        const formData = new FormData()
        const files = this.deviationObj.files

        if (files) {
          Array.from(files).forEach((file, i) => {
            formData.append('document[' + i + ']', file)
          })
        }
        const newobj = this.$formatter.removeInvalidFromObject(model)
        setTimeout(() => {
          for (const key in newobj) {
            if (key !== 'files') formData.append(key, key === 'is_completed' ? newobj[key] : newobj[key] || null)
          }
          const header = {
            'Content-Type': 'multipart/form-data'
          }
          let url = `https://${this.hostRef}/api/`
          // const method = model.id ? 'put' : 'post'
          url += model.id ? `deviation/${model.id}/update` : 'deviation'
          const text = model.id ? 'message.common.updatedSuccess' : 'message.common.addedSucess'
          /* on update check if there is documents in array to delete */
          const ids = [...this.deleteDocumentIds]
          if (model.id && ids.length > 0) formData.append('document_delete_ids', ids)
          hostAppApi.post(url, formData, { headers: header }) // `${url}?locale=${this.$i18n.locale}`
            .then((response) => {
              this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
              this.$router.push(this.backUrl)
            })
            /* .catch(error => {
              if (error.response) {
                let exception = error.response
                if (error.response.data) exception = `Status Code : ${error.response.status}, Message : ${error.response.data}`
                else exception = `Status Code : ${error.response.status}, Message : ${error.response.statusText}`
                this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: JSON.stringify(exception), isException: true })
                if (error.response.status === 401) {
                  this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.authFailed' })
                  this.$store.dispatch('logout')
                  this.$router.push('/login').catch(() => {})
                }
              } else {
                this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: JSON.stringify(error.message), isException: true })
              }
            }) */
            .finally(() => {
              this.loading = false
            })
        }, 50)
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.validationIssue' })
    },
    getSingleRecord (id) {
      this.fieldsLoading = true
      hostAppApi.get(`https://${this.hostRef}/api/deviation/${id}/edit?user_id=${this.hostUserId}`)
        .then((response) => {
          if (response.data) {
            const transformData = { date: { list: ['deadline'], from: 'DD.MM.YYYY', to: 'DD.MM.YYYY' } }
            const model = this.$formatter.formatModel(response.data, transformData)
            model.copyto = model.copy_to
            this.deviationObj = this.$formatter.cloneVariable(model)
            this.listOfDocuments = this.$formatter.removeInvalidFromArrayObject(model.documents)
          }
        })
        .finally(() => {
          this.fieldsLoading = false
        })
    }
  },
  watch: {
    'deviationObj.deadlineDatePicker' (val) {
      if (val) {
        this.deviationObj.deadline = this.$formatter.formatDate(val, 'YYYY-MM-DD', 'DD.MM.YYYY')
      }
    },
    'deviationObj.deviation_date' (val) {
      if (val) {
        var maxDate = this.$formatter.formatDate(val, 'DD.MM.YYYY HH:mm', 'YYYY-MM-DD')
        if (maxDate) this.deviationObj.maxEndDate = maxDate
        else this.deviationObj.maxEndDate = this.$formatter.formatDate(val, 'YYYY-MM-DDTHH:mm', 'YYYY-MM-DD')
      }
    },
    isShowDocumentErrors (val) {
      this.hideDetails = !val
    }
  }
}
</script>

<style>

</style>
